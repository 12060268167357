import React, { FC, memo } from 'react';

type Props = {
  countryCode: string;
  className?: string;
  size?: string;
  width?: string;
  height?: string;
};

const CountryFlag: FC<Props> = ({
  countryCode,
  className = '',
  size = '24px',
  height = '',
  width = ''
}) => {
  const src = `/img/flags/1x1/${countryCode}.svg`;
  const styles = {
    width: width && height ? width : size,
    height: height && width ? height : size
  };

  return <img className={className} src={src} alt={countryCode} style={{ ...styles }} />;
};

export default memo(CountryFlag);
