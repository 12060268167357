import React, { FC, useEffect, useState } from 'react';
import { CarOffer } from 'src/cars/types';
import Spinner from 'components/common/spinner';
import useCarsApi from 'providers/cars-api/use-cars-api';
import useTranslation from 'providers/translations/use-translations';
import { CarTerms, CarTermsRequestParams } from 'types/car-terms.type';
import CarIcon from 'components/common/car-icon';
import { getSearchParam } from 'src/url-manager';
import Infobox from 'components/common/infobox';
import Button from 'components/forms/button';
import { getCarTerms } from 'src/cars/car-tools';
import useOffersContext from 'providers/offers/use-offers-context';
import useBookingFormContext from 'providers/booking-form/use-booking-form-context';
import { wsInfo } from 'src/logger';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import { AdditionalOfferOptions } from 'src/booking-engine/types';
import styles from './details.module.scss';
import useIframeContext from 'providers/iframe/use-iframe-context';

interface Props {
  offer: CarOffer;
  additionalOptions?: AdditionalOfferOptions;
}

const CarTermsTab: FC<Props> = ({ offer, additionalOptions = {} }) => {
  const { customProxy } = useDevToolsContext();
  const { handleError } = useCarsApi();
  const { inIframe } = useIframeContext();
  const [loading, setLoading] = useState(!offer?.terms);
  const [terms, setTerms] = useState<CarTerms>(offer?.terms);
  const { searchData } = useOffersContext();
  const { search } = useBookingFormContext();
  const { t } = useTranslation();

  const postData: CarTermsRequestParams = {
    sessionId: getSearchParam('searchid') || additionalOptions?.sessionId || '',
    offerId: offer.offerId,
    bookingId: additionalOptions?.bookingId,
    productId: offer.products.length ? offer.products[0].productId : '',
    pickupStation: offer.pickupStation.code,
    driverAge: searchData?.driverAge ?? search?.driverAge,
    driverCountry: searchData?.driverCountry ?? search?.driverCountry,
    locale: searchData?.locale ?? search?.locale
  };

  const provideTerms = () => {
    if (terms.partnerContractUrl) {
      if (terms.partnerContractUrl.startsWith('https://createpdf') && inIframe === true) {
        return (
          <div className={styles.carDetails__termsButton}>
            <Button
              variant="secondary"
              onClick={() => window.open(terms.partnerContractUrl, '_blank')}
            >
              <CarIcon icon="arrow-right"></CarIcon>
              {t('tab_terms_cond_open', t('0nc_tab_terms_cond'))}
            </Button>
          </div>
        );
      }
      return (
        <iframe
          className={styles.carDetails__iframe}
          src={terms.partnerContractUrl}
          title="terms"
        />
      );
    }
    if (terms.partnerContractHTML) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: terms.partnerContractHTML
          }}
        />
      );
    }

    if (terms.partnerTermsUrl && terms.showPartnerTerms === true) {
      return (
        <iframe className={styles.carDetails__iframe} src={terms.partnerTermsUrl} title="terms" />
      );
    }

    if (terms.partnerTermsHTML && terms.showPartnerTerms === true) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: terms.partnerTermsHTML
          }}
        />
      );
    }

    return (
      <>
        <CarIcon icon="emo-unhappy" size="18px" />
        {t('no_terms_available_at_this_step')}
      </>
    );
  };

  const getTerms = async () => {
    setLoading(true);
    await getCarTerms(offer, postData, undefined, customProxy).then(
      (response) => {
        setLoading(false);
        if (response) {
          setTerms(response);
        } else {
          setTerms({});
        }
      },
      (e) => {
        setLoading(false);
        handleError(e);
      }
    );
  };

  useEffect(() => {
    wsInfo('userInteraction', 'Open offer terms tab', {
      offerId: offer.offerId
    });

    if (terms) return;
    getTerms();
  }, []);

  return (
    <div className={styles.carDetails__terms_container}>
      {loading && <Spinner />}
      {terms && provideTerms()}
      {!loading && !terms && (
        <Infobox level="warning">{t('no_terms_available_at_this_step')}</Infobox>
      )}
    </div>
  );
};

export default CarTermsTab;
