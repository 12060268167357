import { useContext } from 'react';
import { bannersContext } from '.';
import { BannersContextType } from './types';

const useBanners = (): BannersContextType => {
  const { getBanners, banners } = useContext(bannersContext);

  return { getBanners, banners };
};

export default useBanners;
